import * as React from 'react'
import { DrawerContext } from '../../../contexts/drawer-context'
import { Drawer, DrawerProps } from '../Drawer'

export type GlobalDrawerProps = {
  children: React.ReactNode
  id: string
} & Omit<DrawerProps, 'trigger' | 'children'>

export const GlobalDrawer: React.VFC<GlobalDrawerProps> = (props) => {
  const {
    children,
    title,
    id,
    onClose,
    onOpen,
    defaultOpen,
    preventCloseOnEsc,
    ...restProps
  } = props

  const { activeDrawerId, closeDrawer, openDrawer } =
    React.useContext(DrawerContext)

  const open = React.useMemo(() => activeDrawerId === id, [activeDrawerId, id])

  React.useEffect(() => {
    if (defaultOpen) openDrawer(id)
  }, [])

  React.useEffect(() => {
    if (open) onOpen?.()
    else onClose?.()
  }, [open])

  return (
    <Drawer
      {...restProps}
      title={title}
      open={open}
      onClose={() => {
        if (preventCloseOnEsc) return
        closeDrawer()
      }}
      onOpen={() => {
        openDrawer(id)
      }}
    >
      {children}
    </Drawer>
  )
}
